import { ReactElement, ReactNode } from 'react';

import styles from './Callout.module.css';

type CalloutProps = {
  title?: string;
  content: ReactNode;
  icon?: ReactElement;
  button?: ReactElement;
};

const Callout: React.FC<CalloutProps> = ({ title, content, icon, button }) => (
  <div className={styles.wrapper}>
    <div className="flex items-center gap-24">
      {icon && <div className="flex-shrink-0">{icon}</div>}
      <div className="flex-col">
        {title && <p className="font-medium">{title}</p>}
        {content}
      </div>
    </div>
    {button}
  </div>
);

export default Callout;
