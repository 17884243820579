const toSlug = (text: string) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/_/g, '-') // Replace all '_' with '-'
    .replace(/%/g, '') // Replace all % to ''
    .replace(/\//g, '%2F') // Replace / to %2F, url encode characters
    .replace(/[^\w-%*]+/g, '') // Remove all non-word chars except for - % *
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-+|-+$/g, ''); // Trim leading and trailing '-'
};

export default toSlug;
