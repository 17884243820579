import { cssJoin } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';

import { Link } from '@collab/atoms';

import styles from './Cards.module.css';
import Title from './Title';
import { LinkedCardProps } from './types';

const TapCard: React.FC<LinkedCardProps> = ({
  title,
  text,
  headingAs,
  link,
  children,
}) => {
  const isExternal = link.href.startsWith('http');

  const linkComponent = (
    <Link
      href={link.href}
      target={link.target}
      className={cssJoin(styles.card, 'flex-col tap-area md:p-8 rounded')}
    >
      <div className="flex-grow">
        {children}
        <Title headingAs={headingAs}>{title}</Title>
        <p className="text-secondary mt-8">{text}</p>
      </div>
      {link.text && (
        <p className="self-start button-text text-accent-blue mt-8">
          {link.text}
        </p>
      )}
    </Link>
  );

  if (isExternal) {
    return (
      <Track eventLabel={link.href} eventAction="link|click">
        {linkComponent}
      </Track>
    );
  }

  return <>{linkComponent}</>;
};

export default TapCard;
