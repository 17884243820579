import { ResponsiveImageProps } from '@vcc-package/media';

import { AspectRatio, ResponsiveAspectRatio } from '@collab/types/types';

type SmAspectRatio = ResponsiveImageProps['images']['smAspectRatio'];
type MdAspectRatio = ResponsiveImageProps['images']['mdAspectRatio'];

type MdReturnType = { md: MdAspectRatio; sm: SmAspectRatio };

type ReturnType = SmAspectRatio | MdReturnType;

const getAspectRatio = (
  aspectRatio: AspectRatio | ResponsiveAspectRatio,
): ReturnType => {
  if (Array.isArray(aspectRatio))
    return convertAspectRatio(aspectRatio) as SmAspectRatio;

  if (!aspectRatio.mobile)
    return convertAspectRatio(aspectRatio.default) as SmAspectRatio;

  return {
    md: convertAspectRatio(aspectRatio.default),
    sm: convertAspectRatio(aspectRatio.mobile),
  } as MdReturnType;
};

const convertAspectRatio = (aspectRatio: AspectRatio): string =>
  aspectRatio.join(':');

export default getAspectRatio;
