import { cssJoin } from '@volvo-cars/css/utils';

import styles from './H3.module.css';

const H3: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <h3 className={cssJoin('heading-2', styles.wrapper)}>{children}</h3>
);

export default H3;
