import { Icon } from '@volvo-cars/react-icons';
import React, { MouseEvent } from 'react';

import { sectionHeadingClass } from '@collab/organisms/NavMenu/utils/useHeadingRefs';
import { DESKTOP_MIN_WIDTH } from '@collab/style/customThemeTokens';
import { useScrollToId } from '@collab/utils/scrollToId';
import toSlug from '@collab/utils/toSlug';

import styles from './H2.module.css';
import H2Plain from './H2Plain';

export type H2Props = {
  children: string;
  resetMargins?: boolean;
};

const H2: React.FC<H2Props> = ({ children, resetMargins }) => {
  const scrollToId = useScrollToId();

  const headingId = toSlug(children);

  const onLinkIconClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const isMobile = window.innerWidth < DESKTOP_MIN_WIDTH;

    scrollToId(headingId, isMobile);
  };

  return (
    <H2Plain
      id={headingId}
      className={sectionHeadingClass}
      ariaLabel={children}
      resetMargins={resetMargins}
    >
      {children}
      <a
        href={`#${headingId}`}
        className={styles.icon}
        aria-label={`Scroll to ${children}`}
        onClick={onLinkIconClick}
      >
        <Icon icon="link" size={24} color="secondary" />
      </a>
    </H2Plain>
  );
};

export default H2;
