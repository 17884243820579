import React from 'react';

import Card from 'molecules/Card/Card';
import {
  CardProps as BaseCardProps,
  HeadingElement,
} from 'molecules/Card/types';

import styles from './CardGrid.module.css';

export type CardProps = Pick<BaseCardProps, 'link' | 'text' | 'title'>;

export type CardGridProps = {
  headingAs?: HeadingElement;
  cards: CardProps[];
};

const CardGrid: React.FC<CardGridProps> = ({ headingAs, cards }) => (
  <div className={styles.wrapper}>
    {cards.map((props: CardProps, index: number) => (
      <Card headingAs={headingAs} key={index} {...props} />
    ))}
  </div>
);

export default CardGrid;
