import dynamic from 'next/dynamic';

import { H1 } from '@collab/atoms';

import styles from './PageTitle.module.css';

const PageCategory = dynamic(() => import('./PageCategory'));
const StickyTitleHeader = dynamic(
  () => import('./StickyTitleHeader/StickyTitleHeader'),
);

const PageTitle: React.FC<{
  title: string;
  category?: string[];
  versionsComponent?: React.ReactNode;
  sticky?: boolean;
}> = ({ title, category, sticky, versionsComponent = null }) => {
  const isPlain = !!versionsComponent && !!category;

  const wrapperStyles = isPlain ? styles.wrapperPlain : styles.wrapper;

  return (
    <div className={wrapperStyles}>
      <div className={styles.titleHeader}>
        {category && <PageCategory category={category} />}
        {versionsComponent}
      </div>

      {sticky && (
        <StickyTitleHeader>
          {category && <PageCategory category={category} />}
          {versionsComponent}
        </StickyTitleHeader>
      )}
      <H1>{title}</H1>
    </div>
  );
};

export default PageTitle;
