import { Paragraph } from '@collab/atoms';
import { useUserContext } from '@collab/features/user';
import { useToastErrorListener } from '@collab/libs/errors';
import { Callout } from '@collab/molecules';

import { fetchUserErrorToast } from 'features/user/user.errors';
import useLogin from 'libs/users/useLogin';
import useUser from 'libs/users/useUser';

type CalloutContent = {
  title: string;
  text: string;
};

type UserStates = 'loggedOut' | 'nonVolvoAccount';

export type StatusTexts = Record<UserStates, CalloutContent>;

export type LoginStatusCalloutProps = {
  statusTexts: StatusTexts;
};

const LoginStatusCallout: React.FC<LoginStatusCalloutProps> = ({
  statusTexts,
}) => {
  const { isLoggedIn, isUserStatusLoading } = useUserContext();
  const login = useLogin();

  if (isUserStatusLoading) {
    return null;
  }

  return isLoggedIn ? (
    <LoggedInStatus {...statusTexts.nonVolvoAccount} />
  ) : (
    <LoggedOutStatus {...statusTexts.loggedOut} onLogin={() => login()} />
  );
};

const LoggedOutStatus: React.FC<CalloutContent & { onLogin: () => void }> = ({
  title,
  text,
  onLogin,
}) => (
  <Wrapper>
    <Callout
      title={title}
      content={<Paragraph>{text}</Paragraph>}
      button={
        <button type="button" className="button-filled" onClick={onLogin}>
          Log in
        </button>
      }
    />
  </Wrapper>
);

const LoggedInStatus: React.FC<CalloutContent> = ({ text, title }) => {
  const { user, error } = useUser();

  useToastErrorListener(error, fetchUserErrorToast);

  if (!user || user.isVolvoAccount) {
    return null;
  }

  return (
    <Wrapper>
      <Callout title={title} content={<Paragraph>{text}</Paragraph>} />
    </Wrapper>
  );
};

const Wrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => <div className="mt-48 md:mt-64">{children}</div>;

export default LoginStatusCallout;
