import { ResponsiveImage, ResponsiveImageProps } from '@vcc-package/media';
import { cssMerge } from '@volvo-cars/css/utils';

import {
  AspectRatio,
  ResponsiveAspectRatio,
  Sources,
} from '@collab/types/types';
import getAspectRatio from '@collab/utils/getAspectRatio';

import styles from './Image.module.css';

export type ImageProps = {
  src: string | Sources;
  alt: string;
  title?: string;
  aspectRatio?: AspectRatio | ResponsiveAspectRatio;
  className?: string;
};

type ImageInfoProps = {
  alt: string;
  aspectRatio: AspectRatio | ResponsiveAspectRatio;
  src: string | Sources;
};

const Image: React.FC<ImageProps> = ({
  alt,
  src,
  title,
  aspectRatio = [21, 9],
  className,
}) => {
  const imageInfo = getImageInfo({ aspectRatio, alt, src });

  return (
    <ResponsiveImage
      images={imageInfo}
      alt={alt}
      title={title}
      className={cssMerge('object-cover', styles.wrapper, className)}
      loading="lazy"
    />
  );
};

const getImageInfo = ({
  alt,
  aspectRatio,
  src,
}: ImageInfoProps): ResponsiveImageProps['images'] => {
  const sources = getImageSource(src);
  const aspectRatios = getAspectRatio(aspectRatio);

  const imageInfos = {
    sm: { alt, src: sources.sm },
    ...(sources.md && { md: { alt, src: sources.md } }),

    ...(typeof aspectRatios === 'string'
      ? { smAspectRatio: aspectRatios }
      : { smAspectRatio: aspectRatios?.sm, mdAspectRatio: aspectRatios?.md }),
  };

  return imageInfos;
};

const getImageSource = (src: string | Sources) => {
  if (typeof src === 'string') return { sm: src };

  if (!src.mobile) return { sm: src.default };

  return {
    md: src.default,
    sm: src.mobile,
  };
};

export default Image;
