import { getSrcSet, Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import Head from 'next/head';
import { useTheme } from 'vcc-ui';

import { getMediaSources, Source } from '@vcc-www/utils/getMediaSources';

import { heroImageStyles } from './HeroImage.styles';

export type HeroImageProps = {
  src: string;
  srcMobile?: string;
  alt: string;
};

const HeroImage: React.FC<HeroImageProps> = ({ src, srcMobile, alt }) => {
  const { breakpoints } = useTheme();

  const sources = { default: src, fromM: src, untilM: srcMobile };
  const preparedSources = getMediaSources(sources, breakpoints);

  return (
    <>
      <Head>
        {preparedSources.map(({ src, media }: Source) => (
          <link
            rel="preload"
            as="image"
            key={media}
            media={media}
            imageSrcSet={getSrcSet(src)}
          />
        ))}
      </Head>
      <Image_DEPRECATED
        alt={alt}
        sources={sources}
        aspectRatio={{ default: [21, 9], untilM: [1, 1] }}
        objectFit="cover"
        loading="eager"
        extend={heroImageStyles}
      />
    </>
  );
};

export default HeroImage;
