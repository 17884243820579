import PlainCard from './PlainCard';
import TapCard from './TapCard';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({ link, ...props }) =>
  props.withTapArea && link ? (
    <TapCard {...props} link={link} />
  ) : (
    <PlainCard {...props} link={link} />
  );

export default Card;
