import { Track } from '@volvo-cars/tracking';
import { ReactElement } from 'react';

import { Link } from '@collab/atoms';

import Title from './Title';
import { CardProps, LinkProps } from './types';

const PlainCard: React.FC<CardProps> = ({
  children,
  headingAs,
  title,
  text,
  link,
}) => (
  <div className="flex-col">
    <div className="flex-grow">
      {children}
      <Title headingAs={headingAs}>{title}</Title>
      <p className="text-secondary mt-8">{text}</p>
    </div>
    {link && (
      <div className="self-start pt-8">
        <LinkWrapper link={link}>
          <Link
            href={link.href}
            target={link.target}
            className="button-text text-accent-blue"
          >
            {link.text}
          </Link>
        </LinkWrapper>
      </div>
    )}
  </div>
);

const LinkWrapper: React.FC<{
  link: LinkProps;
  children: ReactElement;
}> = ({ link, children }) => {
  const isExternal = link.href.startsWith('http');

  if (isExternal) {
    return (
      <Track eventLabel={link.href} eventAction="link|click">
        {children}
      </Track>
    );
  }

  return <>{children}</>;
};

export default PlainCard;
