import { cssJoin } from '@volvo-cars/css/utils';

import toSlug from '@collab/utils/toSlug';

import styles from './H2.module.css';

type H2PlainProps = {
  resetMargins?: boolean;
  id?: string;
  className?: string;
  ariaLabel?: string;
  children: React.ReactNode;
};

const H2Plain: React.FC<H2PlainProps> = ({
  resetMargins,
  id,
  className,
  ariaLabel,
  children,
}) => {
  const plainID = typeof children === 'string' ? toSlug(children) : undefined;

  const classNames = cssJoin(
    'heading-1',
    styles.base,
    resetMargins && styles.marginReset,
    className,
  );

  return (
    <h2 className={classNames} id={id ?? plainID} aria-label={ariaLabel}>
      {children}
    </h2>
  );
};

export default H2Plain;
