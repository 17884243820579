import { HeadingElement } from './types';

const Title: React.FC<{
  headingAs: HeadingElement | undefined;
  children: string;
}> = ({ headingAs, children }) => {
  if (headingAs === 'h3') {
    return <h3 className="font-20 font-medium">{children}</h3>;
  }

  return <h4 className="font-20 font-medium">{children}</h4>;
};

export default Title;
