import dynamic from 'next/dynamic';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { Paragraph } from '@collab/atoms';

const InlineCode = dynamic(() => import('@collab/atoms/InlineCode'));
const Ol = dynamic(() => import('@collab/atoms/List/Ol'));
const Ul = dynamic(() => import('@collab/atoms/List/Ul'));
const Li = dynamic(() => import('@collab/atoms/List/Li'));
const InlineLink = dynamic(
  () => import('components/mdxComponents/InlineLinkMd'),
);

const components: any = {
  p: Paragraph,
  a: InlineLink,
  ul: Ul,
  li: Li,
  ol: Ol,
  code: InlineCode,
};

const Markdown: React.FC<{ children: string }> = ({ children }) => (
  <ReactMarkdown components={components}>{children}</ReactMarkdown>
);

export default Markdown;
