import { ReactNode } from 'react';

import { useUserContext } from '@collab/features/user';
import { useToastErrorListener } from '@collab/libs/errors';

import { fetchUserErrorToast } from 'features/user/user.errors';
import useUser from 'libs/users/useUser';

const RestrictedVolvoUserContent: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { isLoggedIn, isUserStatusLoading } = useUserContext();

  if (isUserStatusLoading || !isLoggedIn) {
    return null;
  }

  return <VolvoUserView>{children}</VolvoUserView>;
};

const VolvoUserView: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { user, error } = useUser();

  useToastErrorListener(error, fetchUserErrorToast);

  if (!user || !user.isVolvoAccount) {
    return null;
  }

  return <>{children}</>;
};

export default RestrictedVolvoUserContent;
